import React from 'react';
import { object, string } from 'prop-types';
import { FormattedMessage } from '../../../../util/reactIntl';
import { ResponsiveImage } from '../../../../components/index.js';
import { Icon } from '../../../../components';
import IconLogo from '../../../../assets/iconLogo.png';

import css from './Hero.module.css';

const Hero = props => {
  const { title, description, image } = props;
  const { variants = {} } = image?.attributes || {};
  const variantNames = Object.keys(variants);

  return (
    <div className={css.hero}>
      <div className={css.heroWrapper}>
        <div className={css.textsWrapper}>
          <div>
            <div className={css.trapezoidWrapper}>
              <div className={css.trapezoid}></div>
            </div>
            <div className={css.subTitle}><FormattedMessage id="EventPage.subTitle" /></div>
            <div className={css.title}>{title}</div>
            <div className={css.description}>{description}</div>
          </div>
          <div className={css.letterLogo}>
            <img className={css.iconLogo} src={IconLogo} />
          </div>
        </div>
        <div className={css.imageWrapper}>
          <ResponsiveImage
            className={css.imageHero}
            alt={title || ''}
            image={image}
            variants={variantNames}
          />
        </div>
      </div>

    </div>
  );
};

Hero.propTypes = {
  title: string,
  description: string,
  image: object
};

export default Hero;
