import React from 'react';
import { array } from 'prop-types';
import { Icon } from '../../../../components';

import css from './SupportAndHelp.module.css';

const SupportAndHelp = props => {
  const { blocks } = props;

  return (
    <div className={css.contentWrapper}>
      {blocks && blocks?.map(item =>
        <div key={item.blockId} className={css.blockWrapper}>
          <Icon className={css.icon} iconID={item.blockId} source='benefits' />
          <div className={css.title}>{item.title.content}</div>
          <div className={css.line}></div>
          <div className={css.text}>{item.text.content}</div>
        </div>
      )}
    </div>
  );
};

SupportAndHelp.propTypes = {
  blocks: array
};

export default SupportAndHelp;
