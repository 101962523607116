import React from 'react';
import { array, object, string, bool } from 'prop-types';
import { RentalCategoryItem } from '../../../../components';
import { ResponsiveImage } from '../../../../components/index.js';

import css from './EventBlock.module.css';

const EventBlock = props => {
  const { isOdd, title, text, image, categories } = props;
  const { variants = {} } = image?.attributes || {};
  const variantNames = Object.keys(variants);
  const wrapperClasses = isOdd ? css.oddWrapper : css.evenWrapper;
  const textWrapperClasses = isOdd ? css.oddTextWrapper : css.evenTextWrapper;
  const imageWrapperClasses = isOdd ? css.oddImageWrapper : css.evenImageWrapper;

  return (
    <div className={wrapperClasses}>
      <div className={textWrapperClasses}>
        <div className={css.title}>{title}</div>
        <div className={css.text}>{text}</div>
        <div className={css.lineWrapper}>
          <div className={css.line}></div>
        </div>
        <div className={css.categories}>
          {categories.map(item =>
            <RentalCategoryItem key={item.key}
              className={css.item}
              iconClassName={css.categoryIcon}
              category={item} />
          )}
        </div>
      </div>
      <div className={imageWrapperClasses}>
        <ResponsiveImage
          className={css.image}
          alt={title || ''}
          image={image}
          variants={variantNames}
        />
      </div>
    </div>
  );
};

EventBlock.defaultProps = {
  isOdd: true
};


EventBlock.propTypes = {
  isOdd: bool,
  title: string,
  text: string,
  image: object,
  categories: array
};

export default EventBlock;
