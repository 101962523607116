import React from 'react';
import { string } from 'prop-types';
import { makeHighlight } from '../../../../util/richText';
import config from '../../../../config';
import bgLeft from '../assets/br-left.png';
import bgRight from '../assets/bg-right.png';
import IconLogo from '../../../../assets/yellowBlackLogo.png';

import css from './Hero.module.css';

const Hero = props => {
  const { title, subTitle, description, pageId } = props;
  const { CMSPageId } = config;
  const toHighlight = pageId === CMSPageId.forRenters ? 'as a renter' : 'as a vendor';

  return (
    <div className={css.heroWrapper}>
      <div className={css.hexagonsWrapper}>
        <div className={css.hexagons}>
          <img className={css.bgLeft} src={bgLeft} />
          <img className={css.bgRight} src={bgRight} />
          <img className={css.iconLogo} src={IconLogo} />
        </div>
      </div>
      <div className={css.hero}>
        <div className={css.subTitle}>{subTitle}</div>
        <div className={css.title}>{title}</div>
        <div className={css.line}></div>
        <div className={css.description}>{makeHighlight(description, toHighlight, css.textBold)}</div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: string,
  subTitle: string,
  description: string,
  pageId: string
};

export default Hero;
