import React, { useMemo } from 'react';
import { object, array } from 'prop-types';
import { eventsBlocks, eventsGrid } from '../../../events-config.js';
import { duplicateAdjusted } from '../../../util/array.js';
import Hero from './Hero/Hero.js';
import EventBlock from './EventBlock/EventBlock.js';
import AdditionalСategories from './AdditionalСategories/AdditionalСategories.js';
import { categories } from '../../../category-config';

import css from './EventPage.module.css';

const EventPage = props => {
  const { sections } = props;
  const { sectionId, title, description, appearance, blocks } = sections[0] || {};

  const events = [...eventsBlocks, ...eventsGrid];
  const eventOptions = sectionId && events.find(item => item.key === sectionId);

  const categoriesList = useMemo(() => {
    const listCategories = categories.map(item => [{ key: item.key, label: item.label }]);
    const listSubCategories = categories.map(item => item.items);
    const fullListCategories = [...listCategories, ...listSubCategories];
    let list = [];
    fullListCategories.forEach(item => list.push(...item));
    return duplicateAdjusted(list);
  }, [categories]);

  const additionalСategoriesList =
    useMemo(() => {
      const rentals = [{ key: 'rentals', label: 'Rentals', }];
      const adjustedCategories = categoriesList.filter(item => eventOptions?.additionalСategories.includes(item.key));
      return [...adjustedCategories.sort((a, b) => a.key > b.key ? 1 : -1), ...rentals];
    }, [categoriesList, sectionId, eventsBlocks, eventsGrid]);

  const getBlockCategories = (index) =>
    categoriesList.filter(item => eventOptions?.categoriesSection[index].includes(item.key));

  return (
    <div className={css.root}>
      {sections.length > 0 &&
        <Hero
          title={title?.content}
          description={description?.content}
          image={appearance?.backgroundImage}
        />
      }
      {blocks?.map((item, index) =>
        <EventBlock
          key={`block_${index}`}
          isOdd={index % 2 === 0}
          title={item.title.content}
          text={item.text.content}
          image={item.media.image}
          number={index}
          categories={getBlockCategories(index)} />
      )}
      {additionalСategoriesList.length > 1 && // > 1 because we always have "all rentals"
        <AdditionalСategories categories={additionalСategoriesList} />}
    </div>
  );
};

EventPage.defaultProps = {
};

EventPage.propTypes = {
  options: object,
  sections: array,
};

export default EventPage;
