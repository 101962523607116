import React from 'react';
import { object, string, bool } from 'prop-types';
import { ResponsiveImage, Icon } from '../../../../components/index.js';
import { makeHighlight } from '../../../../util/richText';
import config from '../../../../config';
import StripeLogo from '../../../../assets/stripe-logo.png';

import css from './Step.module.css';

const EventBlock = props => {
  const { isOdd, title, text, image, number, pageId } = props;
  const { variants = {} } = image?.attributes || {};
  const { CMSPageId } = config;
  const variantNames = Object.keys(variants);
  const wrapperClasses = isOdd ? css.oddWrapper : css.evenWrapper;
  const textWrapperClasses = isOdd ? css.oddTextWrapper : css.evenTextWrapper;
  const imageWrapperClasses = isOdd ? css.oddImageWrapper : css.evenImageWrapper;
  const isStripeLogo = text.lastIndexOf('[stripe]') > 0;
  const textAdjusted = isStripeLogo ? text.substr(0, text.length - 8) : text;
  const renderStripeLogo = isStripeLogo ? <div className={css.stripeLogo}><img src={StripeLogo} /></div> : null;

  const highlightText = pageId === CMSPageId.forRenters ? 'renter profile' : 'vendor profile';
  const boldText = pageId === CMSPageId.forRenters ? 'renter profile' : 'your vendor profile';
  const isFirstBlock = number === '1';

  return (
    <div className={wrapperClasses}>
      <div className={textWrapperClasses}>
        <div className={css.iconWrapper}>
          <Icon className={css.numberIcon} iconID={number} source='numbers' />
        </div>
        <div className={css.title}>{isFirstBlock ? makeHighlight(title, highlightText, css.highlightText) : title}</div>
        <div className={css.text}>{isFirstBlock ? makeHighlight(text, boldText, css.boldText) : textAdjusted}{renderStripeLogo}</div>
      </div>
      <div className={imageWrapperClasses}>
        <ResponsiveImage
          className={css.image}
          alt={title || ''}
          image={image}
          variants={variantNames}
        />
      </div>
    </div>
  );
};

EventBlock.defaultProps = {
  isOdd: true
};


EventBlock.propTypes = {
  isOdd: bool,
  title: string,
  text: string,
  image: object,
  number: string,
  pageId: string
};

export default EventBlock;
