import React from 'react';
import { string, array } from 'prop-types';
import Hero from './Hero/Hero.js';
import Step from './Step/Step.js';
import SupportAndHelp from './SupportAndHelp/SupportAndHelp.js';
import { CallToActions } from '../../../components';

import css from './HowWorksPage.module.css';

const HowWorksPage = props => {
  const { sections, pageId } = props;
  const { title, description, blocks } = sections?.find(item => item.sectionId === 'hero-and-steps') || {};
  const supportAndHelp = sections?.find(item => item.sectionId === 'support-and-help')?.blocks || [];
  const callToActionsDetails = sections?.find(item => item.sectionId === 'call-to-actions');
  const subTitle = pageId.slice(pageId.lastIndexOf('-') + 1, pageId.length);

  return (
    <div className={css.root}>
      {sections.length > 0 &&
        <Hero
          title={title?.content}
          subTitle={subTitle}
          description={description?.content}
          pageId={pageId}
        />
      }
      {blocks?.map((item, index) =>
        <Step
          key={`block_${index}`}
          isOdd={index % 2 === 0}
          title={item.title.content}
          text={item.text.content}
          image={item.media.image}
          number={String(index + 1)}
          pageId={pageId}
        />
      )}
      {supportAndHelp && <SupportAndHelp blocks={supportAndHelp} />}
      {callToActionsDetails && <CallToActions className={css.callToActions} details={callToActionsDetails} />}
    </div>
  );
};

HowWorksPage.defaultProps = {
};

HowWorksPage.propTypes = {
  sections: array,
  pageId: string,
};

export default HowWorksPage;
