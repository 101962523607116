import React, { useEffect } from 'react';
import { array, number, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../../../util/reactIntl';
import { withViewport } from '../../../../util/contextHelpers';
import { RentalCategoryItem } from '../../../../components';

import css from './AdditionalСategories.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

const AdditionalСategoriesComponent = props => {
  const { categories, viewport } = props;

  useEffect(() => {
    const isMobile = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;
    const isTablet = viewport.width > MAX_MOBILE_SCREEN_WIDTH && viewport.width <= MAX_TABLET_SCREEN_WIDTH;
    const isDesktop = viewport.width > MAX_TABLET_SCREEN_WIDTH;

    const gridColumns = isMobile && categories.length >= 3
      ? 3
      : isTablet && categories.length >= 4
        ? 4
        : isDesktop && categories.length >= 6
          ? 6
          : categories.length;

    window.document.documentElement.style.setProperty('--additionalСategoriesGridColumns', gridColumns);
  }, [viewport.width, categories]);

  return (
    <div className={css.categories}>
      <div className={css.categoriesWrappers}>
        <div className={css.title}><FormattedMessage id="AdditionalСategories.title" /></div>
        <div className={css.listWrapper}>
          {categories.map(item =>
            <RentalCategoryItem key={item.key}
              className={css.item}
              iconClassName={css.categoryIcon}
              category={item} />
          )}
        </div>
      </div>
    </div>
  );
};

AdditionalСategoriesComponent.propTypes = {
  categories: array,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

};

const AdditionalСategories = compose(withViewport)(AdditionalСategoriesComponent);

export default AdditionalСategories;
